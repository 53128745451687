<template>
  <div id="app">
    <Feedback v-if="!report"/>
    <Report v-if="report"/>
  </div>
</template>

<script>
import Feedback from './components/Feedback.vue'
import Report from './components/Report.vue'

export default {
  name: 'App',
  components: {
    Feedback, Report
  },
  data() {
    return {
      report: false
    }
  },
  beforeMount() {
    this.report = (location.href?.endsWith('report'))
  }
}
</script>

<style>
#app {
  @import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
  font-family: "Merienda", Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 20px;
}

</style>
