<template>
  <div class="feedback">
    <div v-if="!isComplete">
      <div class="question">What is the source of your issue?</div>
      <div class="error" v-if="issueError">Required</div>
      <select v-model="issueSource">
        <option value="none">Select one...</option>
        <option value="iOS">Yourgi App (Apple iOS)</option>
        <option value="Android">Yourgi App (Android)</option>
      </select>

      <div class="question">Your email address?</div>
      <div class="error" v-if="emailError">{{ emailMessage }}</div>
      <input v-model="email" maxlength="80" size="30" class="text-input"/>

      <div class="question">I am a staff member at a pet center or animal hospital?</div>
      <select v-model="isStaff">
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>

      <div class="question">What is the issue you are experiencing?</div>
      <div class="error" v-if="issueDescriptionError">Required</div>
      <textarea v-model="issueDescription" maxlength="4000" rows="12" cols="30">
      </textarea>
      
      <div class="question">Add screenshots (optional)?</div>
      <input type="file" @change="selectFile" id="file1" class="file-input"/><br/>
      <div v-if="showFile2"><input type="file" @change="selectFile" id="file2" class="file-input" /><br/></div>
      <div v-if="showFile3"><input type="file" @change="selectFile" id="file3" class="file-input" v-if="showFile3"/><br/></div>

      <br>
      <div class="buttonArea">
        <button name="submit" type="submit" @click="submit" :disabled="submitDisabled">
          {{ submitLabel }}
        </button>
      </div>
    </div>
    <div v-if="isComplete" class="thankyou">
      <div class="question">Thank you for your feedback.</div>
      We appreciate your comments and will use them to improve Yourgi.
    </div>

  </div>
</template>

<script>
export default {
  name: 'Feedback',
  data() {
    return {
      issueSource: "none",
      email: null,
      isStaff: 0,
      issueDescription: null,
      submitDisabled: false,
      submitLabel: "Submit",
      issueError: false,
      emailError: false,
      issueDescriptionError: false,
      emailMessage: null,
      isComplete: false,
      file1: null,
      file2: null,
      file3: null,
      showFile2: false,
      showFile3: false
    }
  },
  methods: {
    selectFile(event) {
      const fileId = event?.srcElement?.['id']
      if (fileId)
        this[fileId] = event

      if (fileId === 'file1') this.showFile2 = true
      if (fileId === 'file2') this.showFile3 = true

      //console.log('event', event)
    },

    /**
     * Button submit action
     */
    async submit() {
      let valid = this.validate()
      if (valid) {
        this.submitDisabled = true
        this.submitLabel = 'Sending...'
        const response = await this.postApi()

        if (response.status === "Success") {
          const filePromises = []
          if (this.file1) filePromises.push(this.postFile(response.data.id, this.file1))
          if (this.file2) filePromises.push(this.postFile(response.data.id, this.file2))
          if (this.file3) filePromises.push(this.postFile(response.data.id, this.file3))

          const filePostResponse = await Promise.all(filePromises)

          const uploads = []
          let counter = 0
          filePostResponse.map(response => {
            this.submitLabel = 'Uploading Files...'
            counter++
            //console.log(this['file'+counter]?.target?.files?.[0])
            uploads.push(this.putFile(response.data.uploadurl, this['file'+counter]?.target?.files?.[0]))
            //console.log(response.data)
          })
          await Promise.all(uploads)
        }
        
        this.issueSource = "none"
        this.email = null
        this.issueDescription = null
        this.isStaff = 0
        this.submitDisabled = false
        this.isComplete = true
      }
    },
    
    /**
     * Validate input
     */
    validate() {
      this.issueError = (this.issueSource === "none")
      this.issueDescriptionError = !this.issueDescription
      if (!this.email) {
        this.emailError = true
        this.emailMessage = 'Required'
      } else if (!this.validateEmail(this.email)) {
        this.emailError = true
        this.emailMessage = 'Please provide a valid email address'
      } else {
        this.emailError = false
        this.emailMessage = null
      }
      return !this.issueError && !this.issueDescriptionError && !this.emailError
    },

    /**
     * Post feedback api
     */
    async postApi() {
      const API = `https://api.destpet.net/feedback/v1/feedback`
      const payload = {
        email: this.email, 
        issueSource: this.issueSource, 
        isStaff: Number(this.isStaff),
        description: this.issueDescription
      }
      const result = await fetch(API, 
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(payload)
        }
      )
      return  await result.json()
    },

    /**
     * Post file info
     */
    async postFile(issueId, fileInfo) {
      const API = `https://api.destpet.net/feedback/v1/photos`
      const payload = {
        name: fileInfo.target.files[0].name, 
        issueId: issueId
      }
      //console.log('Creating photo', payload.name)
      const result = await fetch(API, 
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(payload)
        }
      )
      return await result.json()
    },

    /**
     * Post file buffer
     */
    async putFile(url, file) {
      const headers = { 'Content-Type': file.type }
      //console.log('uploading file to s3', file.name)
      await fetch(url,
        {
          method: 'PUT',
          headers,
          body: file,
        }
      )
    },
   
    /**
     * Validate email address
     */
    validateEmail(email) {
      return email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feedback {
  font-size: 18px;
  font-weight: normal;
  color: #363636;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
select {
  padding: 10px;
  margin-top: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;

}
textarea {
  padding: 10px;
  margin-top: 10px;
}
button {
  padding: 10px;
  margin-top: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.question {
  margin: 10px 0 0;
}
.text-input {
  padding: 10px;
  margin-top: 10px;
}
.file-input {
  padding: 5px;
  margin-top: 5px;
}
.buttonArea {
  margin-left: 0px;
}
.error {
  color: red;
}
.thankyou {
  margin-top: 60px;
  color: blue;
}
</style>
