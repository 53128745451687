<template>
  <div class="formArea">

    <h3>Username</h3>
    <input v-model="email" maxlength="80" size="30" />

    <h3>Password</h3>
    <input v-model="password" maxlength="30" size="30" type="password" />
    
    <br>
    <div class="buttonArea">
        <div class="error" v-if="isError">Login failed</div>
        <button name="submit" type="submit" @click="submit" :disabled="disableSubmit">
            {{ submitLabel }}
        </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Report',
  data() {
    return {
      email: null,
      password: null,
      isError: false,
      submitLabel: 'Report',
      buttonEnabled: true
    }
  },
  computed: {
    disableSubmit() {
        return !this.email || !this.password || !this.buttonEnabled
    }
  },
  methods: {

    /**
     * Button click action
     */
    async submit() {
        this.isError = false
        this.buttonEnabled = false
        this.submitLabel = 'Loggin In...'
        const jwt = await this.postLogin()
        if (jwt) {
            this.submitLabel = 'Creating Report...'
            const reportResponse = await this.getReport(jwt)
            if (reportResponse.status === 'Success' && reportResponse.data)
                this.forceFileDownload(reportResponse.data)
        } else {
          this.isError = true
        }
        
        this.buttonEnabled = true
        this.submitLabel = 'Report'
    },

    /**
     * Pull report from api
     */
    async getReport(jwt) {
      const API = `https://api.destpet.net/feedback/v1/issues-csv`
      const result = await fetch(API, 
        {
          headers: {
            'Authorization': jwt
          }
        }
      )
      return await result.json()
    },

    /**
     * Login
     */
    async postLogin() {
      const API = `https://api.destpet.net/user/v1/emp/login`
      const payload = {
        username: this.email, 
        password: this.password
      }
      const result = await fetch(API, 
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(payload)
        }
      )
      const response = await result.json()
      let jwt
      if (response.status === 'Success') {
        jwt = response.identityToken
      } else {
        this.enableSubmit = false
      }
      return jwt
    },

    /**
     * Push download
     */
    forceFileDownload(data) {
      const url = window.URL.createObjectURL(new Blob([data], {type: "text/csv"}))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'issues.csv')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 10px 0 0;
}
input {
  padding: 10px;
  margin-top: 10px;
}
button {
  padding: 10px;
  margin-top: 10px;
}
.buttonArea {
  margin-left: 0px;
}
.error {
  margin-top: 10px;
  color: red;
}
.formArea {
    margin-top: 40px;
}
</style>
